import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './App.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AppBar, Box, Toolbar } from '@mui/material';

import AvinorLogo from './assets/logos/Avinor_logo_hvit.svg';
import OneparkLogo from './assets/logos/Onepark_logo.svg';

// Pages
import HomePage from './pages/HomePage/HomePage';

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ backgroundColor: '#FFFFFF' }}>
            <Toolbar>
              <div
                style={{
                  width: '120px',
                  height: '50px',
                  backgroundImage: `url(${OneparkLogo})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
              <Box sx={{ flexGrow: 1 }} />
              {/*
              <div
                style={{
                  width: '120px',
                  height: '50px',
                  backgroundImage: `url(${OneparkLogo})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
              */}
            </Toolbar>
          </AppBar>
        </Box>
        <BrowserRouter>
          <Routes>
            <Route path="/invite/:fid/:bid/:iid" element={<HomePage />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </div>
  );
}

export default App;
