/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';

import './HomePage.scss';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';

import * as BookingDB from '../../db/repositories/booking';
import formatFirestoreTimestamp from '../../utils/format-firestore-timestamp';
import formatUnixTimestamp from '../../utils/format-unix-timestamp';
import apiLocal from '../../utils/api-call/apiLocal';

function HomePage() {
  const { fid, bid, iid } = useParams();

  const [globalError, setGlobalError] = useState<boolean>(false);

  const [inviteData, setInviteData] = useState<any>(undefined);

  const [loadingBooking, setLoadingBooking] = useState<boolean>(true);
  const [booking, setBooking] = useState<any>(undefined);

  const [loadingInvite, setLoadingInvite] = useState<boolean>(false);
  const [invite, setInvite] = useState<any>(undefined);

  const [licencePlate, setLicencePlate] = useState<string>('');

  const [loadingAddBooking, setLoadingAddBooking] = useState<boolean>(false);
  const [addBookingSuccess, setAddBookingSuccess] = useState<boolean>(false);

  useEffect(() => {
    /*
    if (fid && bid && iid) fetchBooking(fid, bid);
    if (fid && bid && iid) fetchInvite(fid, bid, iid);
    */
    if (fid && bid && iid) postAddNewVehicles(fid, bid, iid);
  }, [fid, bid, iid]);

  /*========================================
  API-calls
  ========================================*/
  const postAddNewVehicles = async (
    fid_val: string,
    bid_val: string,
    iid_val: string,
  ) => {
    const path = `/api/invite/get`;
    const method = 'POST';
    const body = {
      fid: fid_val,
      bid: bid_val,
      iid: iid_val,
    };
    const data = await apiLocal({ path, method, body });

    if (data.status === 'ok') {
      setInviteData(data.data);
    } else {
      setGlobalError(true);
    }
    setLoadingInvite(false);
    setLoadingBooking(false);
    return false;
  };

  const postNewBookingVehicles = async (
    fid_val: string,
    bid_val: string,
    iid_val: string,
    plate: string,
  ) => {
    const path = `/api/invite/post`;
    const method = 'POST';
    const body = {
      fid: fid_val,
      bid: bid_val,
      iid: iid_val,
      plate: plate,
    };
    const data = await apiLocal({ path, method, body });

    if (data.status === 'ok') {
      setAddBookingSuccess(true);
      //setInviteData(data.data);
    } else {
      setGlobalError(true);
    }
    setLoadingInvite(false);
    setLoadingBooking(false);
    return false;
  };

  const handlePostNewParking = () => {
    if (fid && bid && iid && licencePlate) {
      setLoadingAddBooking(true);
      postNewBookingVehicles(fid, bid, iid, licencePlate);
    }
  };

  const handleNewPermitRestart = () => {
    if (fid && bid && iid) {
      postAddNewVehicles(fid, bid, iid);
      setLoadingInvite(true);
      setLoadingAddBooking(false);
      setLicencePlate('');
      setAddBookingSuccess(false);
    }
  };

  if (!fid || !bid || !iid || globalError) {
    return (
      <Fragment>
        <Grid container marginTop={2} justifyContent="center">
          <Grid item sx={{ maxWidth: 320 }}>
            <Alert severity="error">Denne invitasjonen er ikke gyldig.</Alert>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  if (loadingBooking || loadingInvite) {
    return (
      <Fragment>
        <Grid container marginTop={2} justifyContent="center">
          <Grid
            container
            marginTop={3}
            marginBottom={3}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Grid container marginTop={2} justifyContent="center">
        <Grid item sx={{ maxWidth: 420 }}>
          <Paper sx={{ p: 3, ml: 2, mr: 2 }}>
            <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
              Parkeringstillatelse
            </Typography>
            <Divider />
            <Typography variant="body1" component="p" sx={{ mt: 1 }}>
              {loadingBooking ? (
                <Skeleton />
              ) : (
                <>
                  Fra:{' '}
                  <b>
                    {inviteData?.from
                      ? formatUnixTimestamp(inviteData.from)
                      : '-'}
                  </b>
                </>
              )}
            </Typography>
            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
              {loadingBooking ? (
                <Skeleton />
              ) : (
                <>
                  Til:{' '}
                  <b>
                    {inviteData?.to ? formatUnixTimestamp(inviteData.to) : '-'}
                  </b>
                </>
              )}
            </Typography>
            <Divider />
            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
              {loadingBooking ? (
                <Skeleton />
              ) : (
                <>
                  Tillatelse: <b>{inviteData?.permit.title}</b> (
                  {inviteData?.permit.description})
                </>
              )}
            </Typography>
            <Divider />
            <Typography variant="body1" component="p" sx={{ mt: 1, mb: 1 }}>
              {loadingBooking ? (
                <Skeleton />
              ) : (
                <>
                  Gyldig: <b>{inviteData?.times_allowed} ganger</b> (
                  {inviteData?.times_used} ganger brukt)
                </>
              )}
            </Typography>
            <Divider />
            {addBookingSuccess ? (
              <Fragment>
                <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
                  Kjøretøy <b>{licencePlate}</b> er gitt parkeringstillatelse.
                </Alert>
                <Divider />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '24px',
                    marginBottom: '12px',
                  }}
                >
                  <Button
                    sx={{
                      pl: 4,
                      pr: 4,
                      color: '#2C8246',
                    }}
                    onClick={() => handleNewPermitRestart()}
                  >
                    Ny parkeringstillatelse
                  </Button>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {loadingAddBooking ? (
                  <Grid
                    container
                    marginTop={5}
                    marginBottom={3}
                    justifyContent="center"
                  >
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                ) : (
                  <Fragment>
                    {inviteData?.times_used >= inviteData?.times_allowed ? (
                      <Alert severity="error" sx={{ mt: 2 }}>
                        Denne invitasjonen er brukt antall tillatte ganger, og
                        er følgelig ikke lenger gyldig.
                      </Alert>
                    ) : (
                      <Fragment>
                        <Box sx={{ flexGrow: 1, height: '100%', mt: 3 }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <FormControl
                              sx={{ m: 0, minWidth: 320, maxWidth: 320 }}
                            >
                              <Alert severity="info" sx={{ mb: 3 }}>
                                Oppgi registreringsnummeret til kjøretøyet som
                                skal motta parkeringstillatelse.
                              </Alert>
                              <TextField
                                id="license-plate"
                                label="Registreringsnummer"
                                value={licencePlate}
                                onChange={e => {
                                  setLicencePlate(
                                    e.target.value
                                      .replace(/[^a-z0-9]/gi, '')
                                      .toUpperCase(),
                                  );
                                }}
                                sx={{ mb: 0 }}
                              />
                            </FormControl>
                          </div>
                        </Box>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '24px',
                            marginBottom: '12px',
                          }}
                        >
                          <Button
                            variant="contained"
                            size="large"
                            disabled={!licencePlate}
                            sx={{
                              pl: 4,
                              pr: 4,
                              backgroundColor: '#2C8246',
                              '&:hover': {
                                backgroundColor: '#37A258',
                              },
                            }}
                            onClick={() => handlePostNewParking()}
                          >
                            Få Parkeringstillatelse
                          </Button>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default HomePage;
