export interface ApiProps {
  path: string;
  method?: 'POST' | 'GET';
  body?: string | object;
  headers?: HeadersInit;
}

export default async ({
  path,
  method = 'GET',
  body,
  headers = { 'Content-Type': 'application/json' },
}: ApiProps) => {
  const controller = new AbortController();
  try {
    const res = await fetch(`${path}`, {
      method: method,
      headers: headers,
      body: typeof body === 'object' ? JSON.stringify(body) : undefined,
    });

    if (!res.ok) {
      const error = await res.json();
      return { error: error };
    }
    return await res.json();
  } catch (err) {
    return { error: err };
  } finally {
    controller.abort();
  }
};
